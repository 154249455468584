<template>
  <div>
    <a-row type="flex" align="bottom">
      <a-col :span="21">
        <h1>Blocked Account</h1>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <div class="card-full">
          <div class="detail">
            <TableBlockedAccount />
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import TableBlockedAccount from '@/components/app/Admin/TableBlockedAccount'
export default {
  data() {
    return {
    }
  },
  components: {
    TableBlockedAccount,
  },
}
</script>

<style>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 20px;
}
</style>
